<template>
  <div>
    <HeaderPanel
      title="Admin"
      :filter="filter"
      placeholder="Search Name"
      @search="getAdmin"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      routerPath="/managePermission/detail/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <Table
        :filter="filter"
        :pageOptions="pageOptions"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :rows="rows"
        :showingTo="showingTo"
        @filterPage="filterPage"
      />
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @searchAll="getAdmin"
      @clearFilter="clearFilter"
    >
      <template v-slot:checkbox-option>
        <b-form-group>
          <template #label>
            <b>Role:</b><br />
            <b-form-checkbox
              v-model="RoleSelectedAll"
              aria-describedby="flavours"
              aria-controls="flavours"
              @change="RoleToggleAll"
            >
              All
            </b-form-checkbox>
          </template>

          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="filter.role"
              :options="Role"
              textField="name"
              valueField="id"
              :aria-describedby="ariaDescribedby"
              class="ml-4"
              stacked
            ></b-form-checkbox-group>
          </template>
        </b-form-group>
      </template>
    </SideBarFilter>
    <router-view></router-view>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";
import Table from "@/components/admin/Table";
import SideBarFilter from "@/components/SideBarFilter";

export default {
  name: "admin",
  components: {
    HeaderPanel,
    SideBarFilter,
    Table,
  },
  data() {
    return {
      Role: [{ name: "Shopee", id: 1 }],
      RoleSelected: [],
      RoleSelectedAll: false,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        status: [],
        role: [],
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "displayName",
          label: "Name",
        },
        {
          key: "roleName",
          label: "Role",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
        },
        { key: "actions", label: "" },
      ],
      items: [],
    };
  },
  created() {
    this.getAdmin();
    this.getRole();
  },
  watch: {
    "filter.role"(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.RoleSelectedAll = false;
      } else if (newValue.length === this.Role.length) {
        this.RoleSelectedAll = true;
      } else {
        this.RoleSelectedAll = false;
      }
    },
  },
  methods: {
    RoleToggleAll(checked) {
      this.filter.role = checked ? this.Role.map((el) => el.id).slice() : [];
    },
    async getRole() {
      const { data } = await this.$Axios(
        `${process.env.VUE_APP_API}/Admin/getFilterList`
      );
      this.Role = data.detail;
    },
    async getAdmin() {
      this.isBusy = true;
      const response = await this.$Axios.post(
        `${process.env.VUE_APP_API}/Admin/getList`,
        this.filter
      );
      this.isBusy = false;
      this.items = response.data.detail.adminList;
      this.rows = response.data.detail.total;
    },

    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    filterPage(filter) {
      this.filter = filter;
      this.getAdmin();
    },
    clearFilter() {
      this.filter = {
        search: "",
        status: [],
        role: [],
        page: 1,
        take: 10,
      };
    },
  },
};
</script>
